import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'

const routes = [{
        name: "login",
        path: "/login",
        component: () =>
            import ('../views/Login.vue'),
        meta: {
            middleware: "guest",
            title: `Login`
        }
    },
    {
        name: "register",
        path: "/register",
        component: () =>
            import ('../views/Register.vue'),
        meta: {
            middleware: "guest",
            title: `Register`
        }
    },
    {
        path: "/",
        component: () =>
            import ('../views/Layouts/Dashboard.vue'),
        meta: {
            middleware: "auth"
        },
        children: [{
            name: "dashboard",
            path: '/',
            component: () =>
                import ('../views/Home.vue'),
            meta: {
                title: `Home`
            }
        }]
    },
    {
        path: "/",
        component: () =>
            import ('../views/Layouts/Dashboard.vue'),
        meta: {
            middleware: "auth"
        },
        children: [{
            name: "paquetesRetirados",
            path: '/retirados',
            component: () =>
                import ('../views/Home.vue'),
            meta: {
                title: `Paquetes retirados`
            }
        }]
    },
    {
        path: "/",
        component: () =>
            import ('../views/Layouts/Dashboard.vue'),
        meta: {
            middleware: "auth"
        },
        children: [{
            name: "paquetesBuscados",
            path: '/buscar',
            component: () =>
                import ('../views/Home.vue'),
            meta: {
                title: `Buscar paquetes`
            }
        }]
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    document.title = `${to.meta.title} - ${process.env.VUE_APP_NAME}`
    if (to.meta.middleware == "guest") {
        if (store.state.auth.authenticated) {
            next({ name: "dashboard" })
        }
        next()
    } else {
        if (store.state.auth.authenticated) {
            next()
        } else {
            next({ name: "login" })
        }
    }
})

export default router
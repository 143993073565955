import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import { createI18n } from 'vue-i18n'
import i18nEs from './i18n/es.json'
import i18nEn from './i18n/en.json'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import "./assets/css/dashboard.css"

axios.defaults.withCredentials = true;
axios.defaults.baseURL = `${process.env.VUE_APP_API_URL}/api`;
axios.defaults.headers.common['Accept'] = 'application/json';

const messages = {
    es: i18nEs,
    en: i18nEn,
};

const i18n = createI18n({
    locale: 'es',
    fallbackLocale: 'es',
    messages
})

createApp(App)
    .use(i18n)
    .use(store)
    .use(router)
    .mount('#app');